<template>
    <div
        :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', margin: '-30px', height: '110vh' } : {}">
        <div v-if="exibirMensagemSucesso" class="mensagemdesucesso-container">
            <img src="../assets/img/certo.png" class="mensagemsucesso-icon">
            <label style="color: #101142; font-size: 16px; font-family: 'Montserrat';">OBRIGADO POR<br>RESPONDER A PESQUISA</label>
            <button @click=fecharMensagemSucesso() class="btn-enviar"
                style="margin-top: 5%; font-size: 18px; width: 220px;">FECHAR</button>
        </div>

        <div class="headerr">
            <img 
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { opacity: '0' } : { width: '15%' }"
            src="../assets/img/LOGO.png" class="logo">

        </div>

        <h1 class="titulo"
            :style="exibirMensagemSucesso ? { color: 'black', marginTop: '30px', marginBottom: '30px' } : { color: '#101142', marginTop: '30px', marginBottom: '30px' }">
            Confirme seus dados e reserve seu lugar no maior evento de Customer Experience com 40% OFF</h1>


        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="nome" class="inputpadrao" type="text" placeholder="Nome Completo" @input="blockNumbers"
                @keydown.enter="focusNext('campo2')">

            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="dataNascimento" class="inputpadrao" 
                type="text" 
                placeholder="Data de Nascimento" 
                @input="formatDate"
                pattern="\d{2}-\d{2}-\d{4}" 
                maxlength="10">

            <input
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
            v-model="email" class="inputpadrao" type="email" placeholder="E-mail" @input="formatInput" ref="campo5"
            @keydown.enter="focusNext('campo6')">

            <input
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
            placeholder="Telefone" v-model="telefoneFormatado" class="inputpadrao" type="tel"
            @input="formatarTelefone" maxlength="15" minlength="15" ref="campo5">

            <select v-model="nivelHierarquico" class="selectpadrao"
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}">
                <option value="" disabled selected hidden>Nível Hierárquico</option>
                <option value="CEO">CEO</option>
                <option value="PRESIDENTE">Presidente</option>
                <option value="VICE-PRESIDENTE">Vice-presidente</option>
                <option value="DIRETOR">Diretor</option>
                <option value="GERENTE">Gerente</option>
                <option value="COORDENADOR">Coordenador</option>
                <option value="SUPERVISOR">Supervisor</option>
                <option value="ANALISTA">Analista</option>
                <option value="OUTROS">Outros</option>
            </select>

            <select v-model="setor" class="selectpadrao"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}">
                <option value="" disabled selected hidden>Setor</option>
                <option value="ADMINISTRACAO">Administração</option>
                <option value="ATENDIMENTO AO CLIENTE">Atendimento ao cliente</option>
                <option value="COMPRAS">Compras</option>
                <option value="CONTABIL">Contábil</option>
                <option value="FINANCEIRO">Financeiro</option>
                <option value="JURIDICO">Jurídico</option>
                <option value="LOGISTICA">Logística</option>
                <option value="MARKETING E VENDAS">Marketing e vendas</option>
                <option value="PRODUCAO">Produção</option>
                <option value="RECURSOS HUMANOS">Recursos humanos</option>
                <option value="SAUDE">Saúde</option>
                <option value="SUPRIMENTOS">Suprimentos</option>
                <option value="TECNOLOGIA DA INFORMACAO">Tecnologia da informação</option>
            </select>

            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="empresa" class="inputpadrao" type="text" placeholder="Empresa" ref="campo3"
                @keydown.enter="focusNext('campo4')">

            <input
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
            v-model="linkedin" class="inputpadrao" type="text" placeholder="Perfil do LinkedIn"
            ref="campo4">

            <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
            
            <button class="btn-enviar" @click="cadastrar()"
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { display: 'none' } : { fontSize: '13px' }">FINALIZAR</button>

            <transition>
                <div v-if="erro" class="msgErroContainer">
                    <img src="../assets/img/report.png" style="height: 20px; margin-bottom: 10px;">
                    <span class="msgErro">{{ erro }}</span>
                </div>
            </transition>
        </div>
        <br>
    </div>
</template>

<script>

import axios from 'axios';

const api = axios.create({
    baseURL: 'https://dev.newbacon.com/grupopadrao/005_LP_Enriquecimento_Api/',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default {
    name: 'Enriquecimento',
    data() {
        return {
            nome: '',
            dataNascimento: '',
            empresa: '',
            email: null,
            telefone: '',
            telefoneFormatado: '',
            linkedin: '',
            setor: '',
            nivelHierarquico: '',
            erro: null,
            exibirMensagemSucesso: false,
        };
    },

    computed: {
    },

    props: {
        dadoenviado: {
            type: String,
            required: false,
        },
    },

    methods: {

        focusNext(refName) {
            this.$refs[refName].focus();
        },
        formatInput(event) {
            this.email = event.target.value.toLowerCase();
        },
        blockNumbers(event) {
            this.nome = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        },
        formatDate(event) {
            let input = this.dataNascimento.replace(/\D/g, '');
            
            if (input.length > 2) {
                input = input.slice(0, 2) + '/' + input.slice(2);
            }
            if (input.length > 5) {
                input = input.slice(0, 5) + '/' + input.slice(5);
            }

            this.dataNascimento = input;
        },

        fecharMensagemSucesso() {
            this.exibirMensagemSucesso = false; 
        },

        blockNumberstoo(event) {
            this.cargo = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        },

        formatarTelefone() {
            let apenasNumeros = this.telefoneFormatado.replace(/\D/g, '');
            if (apenasNumeros.length > 11) {
                apenasNumeros = apenasNumeros.slice(0, 11);
            }
            if (apenasNumeros.length <= 10) {
                this.telefoneFormatado = apenasNumeros.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
            } else {
                this.telefoneFormatado = apenasNumeros.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
            }
            this.telefone = apenasNumeros;
        },

         convertToISO(dateStr) {
            const [day, month, year] = dateStr.split('/').map(Number);

            const date = new Date(Date.UTC(year, month - 1, day));

            return date.toISOString();
        },
        
        async cadastrar() {

            if (!this.nome || !this.email) { 
                this.erro = 'Por favor, preencha o nome e o e-mail.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0; 

            } else if (!this.dataNascimento) { 
                this.erro = 'Por favor, preencha sua data de nascimento.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (!this.telefone) { 
                this.erro = 'Por favor, preencha seu telefone.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (!this.telefone) { 
                this.erro = 'Por favor, preencha seu telefone.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (!this.nivelHierarquico) { 
                this.erro = 'Por favor, preencha seu nível hierárquico.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (!this.setor) { 
                this.erro = 'Por favor, preencha seu setor.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (!this.empresa) { 
                this.erro = 'Por favor, preencha o nome da sua empresa.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0;

            } else if (this.validarIdade(this.dataNascimento)) {
                this.erro = 'É necessário ter 18 anos para se cadastrar.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0; 

            } else if (this.validarEmail(this.email)) {
                this.erro = 'Insira um email válido.';
                setTimeout(() => {
                    this.erro = null;
                }, 1000);
                return 0; 
                
            } else {

                const data = {
                    nome: this.nome,
                    dataNascimento: this.convertToISO(this.dataNascimento),
                    email: this.email,
                    telefone: this.telefone,
                    nivelHierarquico: this.nivelHierarquico,
                    setor: this.setor,
                    empresa: this.empresa,
                    linkedin: this.linkedin,
                };

                try {
                    await api.post('v1/Cadastro', data);

                    await this.limparDados();

                    this.exibirMensagemSucesso = true;

                } catch (error) {
                    console.error('Erro ao cadastrar participante:', error);
                }
            }
        },

        validarIdade(dataNascimento) {

            const aniversario = new Date(this.convertToISO(dataNascimento));
            const today = new Date();
            const idade = today.getFullYear() - aniversario.getFullYear();
            const aniversarioPassou = (today.getMonth() > aniversario.getMonth()) || 
                (today.getMonth() === aniversario.getMonth() && today.getDate() >= aniversario.getDate());

            if (idade < 18 || (idade === 18 && !hasBirthdayPassed)) {
                return true;
            } else {
                return false;
            }
        },

        validarEmail(email) {

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailRegex.test(email)) {
                return false;
            } else {
                return true;
            }   
        },

        async limparDados() {
            this.nome = '';
            this.dataNascimento = '';
            this.email = '';
            this.telefone = '';
            this.nivelHierarquico = '';
            this.setor = '';
            this.linkedin = '';
            this.empresa = '';
        }
    },
};

</script>

<style>

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    font-weight: normal;
}

.titulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 12%;
}

.mensagemsucesso {
    background-color: rgb(129, 238, 129);
    color: black;
    padding: 5px;
    border-radius: 3px;
}

.inputpadrao::placeholder {
    font-size: 14px !important;
}

.headerr {
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
    padding-top: 2%;
}

.hover:hover {
    cursor: pointer;
}

.headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    margin: auto;
}


.titulo {
    font-size: 18px;

}

.inputcheck {
    height: 100%;
    width: 25px;
    margin-right: 4%
}

.inputpadrao {
    margin-bottom: 1%;
    border: 2px solid #101142;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #101142;
    padding-left: 10px;
    font-size: 14px !important;
}

input::placeholder {
    color: #101142;
}

.selectpadrao {
    border: 2px solid #101142;
    margin-bottom: 1%;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #101142;
    background-color: #ECECEC;
    padding-left: 10px;
    margin-left: 0.1px;
}


.btn-enviar {
    height: 55px;
    width: 416px;
    background-color: #101142;
    font-size: 25px;
    color: white;
    margin-top: 1%;
    letter-spacing: 1px;
    font-family: 'Montserrat';
    font-weight: 500;
    border: none;
    border-radius: 0px;
}

button:hover {
    cursor: pointer;
}

.btn-enviar:hover {
    cursor: pointer;
}

.btnhover:hover {
    cursor: pointer
}

.v-enter-active, .v-leave-active {
    opacity: 1;
    top: 50%;
    transition: opacity 0.1s ease, top 0.1s ease;
}

.v-enter-from, .v-leave-to {
    top: 60%;
    opacity: 0;
}

.msgErroContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 25px;
    background-color: rgb(255, 224, 224);
    border: 1px solid red;
    border-radius: 5px;
    position:absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.msgErro {
    color: red; 
    position:relative; 
    margin: auto;
}

@media(min-width: 991px) {

    .mensagemsucesso-icon {
        width: 17%;
        margin-bottom: 5%;
    }


    .logo {
        width: 3% !important;
    }

    .inputpadrao {
        width: 250px;
        height: 35px;
        margin-bottom: 1%;
        border: 2px solid #101142;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #101142;
        padding-left: 10px;
        font-size: 14px !important;
    }

    .selectpadrao {
        width: 265px;
        height: 40px;
        margin-bottom: 1%;
        padding-left: 10px;
    }

    .btn-enviar {
        width: 265px !important;
        height: 35px !important;
    }

    .mensagemdesucesso-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ECECEC;;
        height: 40%;
        width: 25%;
        z-index: 9999;
        margin:auto;
        top:50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
    }
}

@media(max-width: 990px) {

    .msgErroContainer {
        height: 10vh;
        width: 50vw;
    }

    .mensagemdesucesso-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ECECEC;
        height: 35%;
        width: 90%;
        z-index: 9999;
        top:50vh;
        transform: translate(-50%, -50%);
        left: 50vw;
        position: absolute;
    }

    .mensagemsucesso-icon {
        width: 25%;
        margin-bottom: 20px;
    }

    .headerr {
        padding-top: 5%;
    }

    

    .mgbt5 {
        padding-top: 5%;
        margin-bottom: 5% !important
    }

    .titulo {
        font-size: 16px;
        margin-top: 7%;
        margin-bottom: 5%;
    }

    .inputpadrao {
        width: 89%;
        margin-bottom: 10px;
        border: 1px solid #101142;
        height: 40px;
        padding-left: 5%;
    }

    .btn-enviar {
        height: 50px;
        width: 90%;
        font-size: 22px;
        background-color: #101142;
        color: white;
        margin-top: 5%;
    }

    label {
        font-size: 16px;
    }

    p {
        font-size: 20px
    }

    .selectpadrao {
        width: 95%;
        height: 45px;
        border: 2px solid #101142;
        margin-bottom: 5%;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #101142;
        background-color: #ECECEC;
        padding-left: 10px;
    }
}


</style>