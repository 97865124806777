<template>
    <div>

        <div v-if="errinho" class="errors">
            O usuário deve pertencer a alguma empresa.
        </div>

        <div class="headerr">


            <img src="../assets/img/LOGO.png" style="width: 12%; max-width: 61px">

            <div class="menu">
                    <div class="menu-item" style="    display: flex; justify-content: end;">
                        <img src="../assets/img/cardapio.png" style="width: 40%;" class="">
                        <div class="submenu">
                            <a @click="this.$router.push('/lista-convidado')">Check-in</a>
                            <a @click="deslogar()" >Deslogar</a>
                        </div>
                </div>
            </div>
                

        </div>



        <p style="font-size: 18px; margin: 30px 0; color: rgb(16, 17, 66);"><strong>CONVIDADOS</strong></p>
        
        <div style="display: flex; align-items: self-end; justify-content: center; min-height: 30px; margin-bottom: 30px">
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="../assets/img/bloqueado.png" style="width: 13px;">
                <input style="width: 20px; height: 20px;" type="radio" name="opcao" :value="null" v-model="opcao">
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="../assets/img/PALESTRANTE.png" style="width: 13px;">
                <input style="width: 20px; height: 20px;" type="radio" name="opcao" value="PALESTRANTE" v-model="opcao">
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="../assets/img/PREMIADO.png" style="width: 13px;">
                <input style="width: 20px; height: 20px;" type="radio" name="opcao" value="PREMIADO" v-model="opcao">
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="../assets/img/VIP.png" style="width: 13px;">
                <input style="width: 20px; height: 20px;" type="radio" name="opcao" value="VIP" v-model="opcao">
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="../assets/img/patrocinador.png" style="width: 13px;">
                <input style="width: 20px; height: 20px;" type="radio" name="opcao" value="PATROCINADOR" v-model="opcao">
            </div>
        </div>

        <p @click="baixarplan()" style="text-decoration: underline; text-align: end; cursor: pointer;">Exportar</p>

        <div class="dada"
            :style="index % 2 === 0 ? { backgroundColor: 'white', color: 'black', height: '40px' } : { backgroundColor: '#F8F8F8', color: 'black', height: '40px' }"
            v-for="(convidado, index) in filteredParticipantes" :key="index">

            <div style="width: 100%;text-align: start; display: flex; justify-content: space-between">

                <div style="display: flex; flex-direction: column;">
                    <label class=" truncate-text" style="color:black; font-size: 16px;">
                        <strong>{{ convidado.nome.charAt(0).toUpperCase() + convidado.nome.slice(1) }}</strong> <img
                            v-if="convidado.tipo.toLowerCase() === 'vip'" src="../assets/img/VIP.png" width="13">
                        <img v-else-if="convidado.tipo.toLowerCase() === 'palestrante'"
                            src="../assets/img/PALESTRANTE.png" width="13">
                        <img v-else-if="convidado.tipo.toLowerCase() === 'premiado'" src="../assets/img/PREMIADO.png"
                            width="13">
                            <img v-else-if="convidado.tipo.toLowerCase() === 'patrocinador'" src="../assets/img/patrocinador.png"
                            width="13">
                    </label>
                    <label style="text-align: start; color: #888888; font-size: 11px;" class="truncate-text">
                        {{ convidado.empresa }}
                    </label>
                </div>
                
            </div>

            <div style="display: flex; align-items: center; gap: 5px;">
                <img  v-if="convidado.isInscrito" src="../assets/img/OUTROS.png" style="width: 20px;">
                <button @click="editagain(convidado)" style="background-color: transparent; border: none;"><img src="../assets/img/editar.png" style="width: 15px;"></button>
                <button @click="imprimir(convidado.nome, convidado.empresa)" style="background-color: transparent; border: none;"><img src="../assets/img/imprimir.png" style="width: 20px;"></button>
            </div>

        </div>


    </div>
</template>


<script>
import api from '@/api';

export default {
    name: 'MostrarConvidados',
    data() {
        return {
            eventoID: null,
            participantes: [],
            opcao: null,
            errinho: false
        }
    },
    methods: {
        editagain(dados){
            localStorage.setItem('editagain', JSON.stringify(dados));
                        this.$router.push('/FormularioEdit')
        },
        baixarplan() {
            api.get(`/v1/participacao/exportar-excel?idEvento=${this.eventoID}`, {
                responseType: 'blob'
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Convidados.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(error => {
                console.error('Erro ao baixar a planilha:', error);
            });
        }
        ,



        imprimir(nome, empresa) {
    api.get(`v1/EventoArquivo/GenerateImage?nome=${nome}&nomeEmpresa=${empresa}`, {
        responseType: 'blob' 
    })
    .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `convidado-${nome}.png`); 
        document.body.appendChild(link);
        link.click();
        link.remove(); 
    })
    .catch((error) => {
        console.log(error);
        this.errinho = true
        setTimeout(()=>{
            this.errinho = false
        }, 1100)
    });
    },
        deslogar() {
            this.$router.push('/');
            localStorage.removeItem('authToken');
        },
        getConvidados() {
            api
                .get(`v1/Participante/evento/${this.eventoID}`)
                .then((resp) => {
                    this.participantes = resp.data
                    console.log(this.participantes)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },
    mounted() {
        this.eventoID = localStorage.getItem('eventoID') || null;
        this.getConvidados()
    },
    computed:{
        filteredParticipantes(){
            if (this.opcao === null) {
                return this.participantes;
            } 
            return this.participantes.filter(convidado => 
            convidado.tipo.toLowerCase() === this.opcao.toLowerCase()
            );

        }
    }
}
</script>


<style scoped>

.menu {
    position: relative;
    display: inline-block;
}

.menu:hover{
    cursor: pointer;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #44444494;
    padding: 5px 0;
    min-width: 100px;
    z-index: 100;
}

.menu-item:hover .submenu {
    display: block;
}

.background-gray {
    background-color: #FFFFFF;
}

a:hover{
    background-color: white;
    color: black !important;
    margin: 0 10px
}

.submenu a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    /* background-color: #44444450; */
}


.dada {
    padding: 5px 5px;
    display: flex;
}

.errors{
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid red;
    border-radius: 10px;
    color: white;
    background-color: red;
    height: 50px;
    width: 200px;
    padding: 5px 5px;
}

</style>