<template>
  <div class="body">

    <img src="../assets/img/logo3.png" alt="">
    <p class="titulo" style="color: #101142; margin-top: 5% !important; margin-bottom: 5% !important">CHECK-IN DE CONVIDADOS</p>

    <div class="container">

      <input class="inputPadrao" 
      type="text" 
      v-model="login" 
      @input="formatInput"
      placeholder="E-mail">
      <input class="inputPadrao" type="password" v-model="senha" placeholder="Senha">
      <select class="inputPadrao2 inputselect" v-model="eventoID">
        <option value="0" style="font-size: 16px;"><label style="font-size: 16px;">Selecione um evento</label></option>
        <option 
          v-for="(evento, index) in eventos"
          :value="evento.idEvento"
          >{{evento.nomeEvento}}</option>
      </select>

      <div class="errorlogin" 
      v-if="(this.mensagem)">
      <label style="font-size: 15px;"><strong>Usuário ou senha incorretos</strong></label>
    </div>

    <div class="errorlogin" 
      v-if="(this.mensagem2)">
      <label style="font-size: 15px;"><strong>Favor selecionar um evento.</strong></label>
    </div>
    </div>

    <button class="btn-logar" 
      @click="logar()" 
      >LOGAR
    </button>




  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'Login',
  data() {
    return {
      login: '',
      senha: '',
      mensagem: false,
      mensagem2: false,
      eventos:[],
      eventoID:0,
      usuarioLogado: false,
      nomeEvento:'',
    }
  },
  watch: {
    eventoID(newID) {
      const selectedEvent = this.eventos.find(evento => evento.idEvento === newID);
      this.nomeEvento = selectedEvent ? selectedEvent.nomeEvento : '';
      localStorage.setItem('nomeEvento', this.nomeEvento);
      localStorage.setItem('eventoID',this.eventoID);
    }
  },
  methods: {
    formatInput(event) {
            this.login = event.target.value.toLowerCase();
        },
        logar() {
  const dados = {
    email: this.login,
    senha: this.senha,
  };

  api
    .post("v1/Login", dados)
    .then((resp) => {
      if (!resp.data) {
        this.mensagem = true;
        this.mensagem2 = false;
      } else if (!this.eventoID && this.eventoID === 0) {
        this.mensagem2 = true;
        this.mensagem = false;
      } else {
        // Salva o login e a senha no localStorage
        localStorage.setItem("login", this.login);
        localStorage.setItem("senha", this.senha);

        this.$router.push("/lista-convidado");
        this.usuarioLogado = true;
        localStorage.setItem("authToken", "yat");
        localStorage.setItem("email", this.login);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
,
    getEvento(){
      api
      .get('v1/Evento')
      .then((resp)=>{
        this.eventos = resp.data;
      })
      .catch((error)=>{
        console.log(error)
      })
    },
  },
  mounted(){
    this.getEvento()

    const savedLogin = localStorage.getItem("login");
  const savedSenha = localStorage.getItem("senha");

  if (savedLogin) {
    this.login = savedLogin;
  }
  if (savedSenha) {
    this.senha = savedSenha;
  }
  }
  

}
</script>

<style scoped>
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf');
  font-weight: normal;
}

.titulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 12%;
}

.subtitulo {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.errorlogin{
  /* background-color: rgb(170, 106, 106); */
  color: rgb(170, 106, 106);;
  border: none;
  padding: 5px;
  border-radius: 3px;
  width: 100%;
}

.texto {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

.inputselect {
  background-color: #ECECEC;
}

.body {
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
}

.inputPadrao {
  margin-bottom: 5%;
  width: 400px;
  height: 55px;
  color: #101142;
  border: 1px #101142 solid;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.inputPadrao2 {
  margin-bottom: 5%;
  width: 406px;
  height: 59px;
  color: #101142;
  border: 1px #101142 solid;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;

}

.btn-logar {
  height: 59px;
  width: 406px;
  background-color: #101142;
  color: white;
  border-radius: 15px;
  font-size: 19px;
}

.btn-logar:hover {
  cursor: pointer;
}

.btn-logar:disabled {
  background-color: aliceblue;
  color: black;
}

@media(max-width: 990px) {
  .container {
    width: 80%;
  }

  .btn-logar {
    width: 276px;
    height: 45px;
    font-size: 16px;
    letter-spacing: 2px;
    position: fixed;
    bottom: 25%;
  }

  .inputPadrao {
    height: 45px;
    width: 270px;
    font-size: 12px;
    font-family: '500', sans-serif;
  }

  .inputPadrao2{
    width: 276px;
    height: 45px;
    font-size: 12px;
  }

  .titulo {
    font-size: 16px;
  }
}
</style>
