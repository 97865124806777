<template>


  <router-view />
</template>

<script>

export default {
  data() {
    return {
      modaativo: false,
    }
  },
  mounted() {

  },
  methods: {

  }
}



</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html{
  padding: 0 20px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.headerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #202020f1;
  z-index: 9999;
}

.overlay2 {
  position: fixed;
  top: 20%;
  left: 7.5%;
  width: 80%;
  height: 75%;
  background: #ffffff;
  z-index: 9999;
}


@media(max-width: 990px) {

  .overlay2 {
    height: 40%;
  }

  .mgbt5 {
    padding-top: 5%;
    margin-bottom: 5% !important
  }


}
</style>
