<template>
    <div :style="confirmbutton ? { backgroundColor: '#101010f1', minHeight: '100vh', margin: '-8px -28px' } : {}">

        <div v-if="active">
            <div v-if="modaativo" class="overlay"
                style="display: flex; justify-content: space-between; padding-top: 9%;">

                <div style="padding-left: 2.5%;">
                    <button class="hover" @click="fecharmodal()"
                        style="border: none; background-color: transparent; color: white; display: flex; align-items: center">
                        <img src="../assets/img/icone-sair.png" alt="">
                        <!-- <label class="hover" for="" style="margin-left: 5%; font-size: 16px; text-decoration: underline;">VOLTAR</label> -->
                    </button>
                </div>

                <div style="display: flex; flex-direction: column; align-items: end; padding-right: 5%;">

                    <a @click="cadastrarconvidados()" class="mgbt5 hover"
                        style="color: white;  text-decoration: underline; vertical-align: top; padding-top: 4px; font-size: 16px">CADASTRAR
                        CONVIDADO
                    </a>
                    <a @click="deslogar()" class="hover"
                        style="color: white; text-decoration: underline; display: flex; align-items: center; font-size: 16px">SAIR<img
                            src="../assets/img/sair.png" style="margin-left: 10%;">
                    </a>

                </div>
            </div>

            <div class="headerr">
                <img style="width: 12%;" src="../assets/img/LOGO.png" class="edittamaho">

                <!-- <button class="hover" @click="deslogar()" style=" border: none ;background-color: transparent; height: auto; display: flex; justify-content: end;"> -->
                <div class="menu">
                    <div class="menu-item" style="display: flex; justify-content: end;">
                        <img src="../assets/img/cardapio.png" style="width: 40%;" class="">
                        <div class="submenu">
                            <a @click="this.$router.push('/mostrar-convidado')">Lista</a>
                            <a @click="deslogar()">Deslogar</a>
                        </div>
                    </div>
                </div>
                <!-- </button> -->

            </div>

            <div v-if="confirmbutton" class="overlay2">
                <div v-if="!tentarcheckin">

                    <div style="display: flex; justify-content: space-between;">
                        <div style="padding: 5% 5%; display: flex;">
                            <button @click="this.confirmbutton = false" class="hover"
                                style="background-color: transparent; border: none; display: flex; justify-content: space-between;">
                                <img src="../assets/img/seta-esquerda.png" style="width: 80%;">
                            </button>
                            <div style="display: flex; flex-direction: column;">
                                <label style="color: #101142; font-size: 12px; text-align: start;"><strong>LISTA DE
                                        CONVIDADOS</strong></label>
                                <label style="color: #101142; font-size: 12px; text-align: start;">Voltar</label>
                            </div>
                        </div>

                        <div v-if="this.tipoparticipante === 'VIP'" style="margin: 5% 5%; padding: 2% 4% ; "><strong>{{
                            this.tipoparticipante
                                }}</strong></div>

                    </div>
                    <div>
                        <p style="font-size: 30px;margin: 0;"><strong>{{ this.nomeparticipante }}</strong></p>
                        <p class="mobizar" style="font-size: 20px; margin: 0; color: #101142;">{{
                            this.empresaparticipante }}</p>
                    </div>

                    <div class="mobizar"
                        style="display: flex; flex-direction: column; justify-content: center; align-items: center">

                        <button @click="editcadastro()" class="tavai mobizar">EDITAR
                            CADASTRO</button>
                        <button @click="tentarfazerCheckin()" class="tavai2">FAZER
                            CHECK-IN</button>

                    </div>

                </div>

                <div v-else>

                    <div style="display: flex; justify-content: space-between;">
                        <div style="padding: 5% 5%; display: flex;">
                            <button @click="this.tentarcheckin = false" class="hover"
                                style="background-color: transparent; border: none; display: flex; justify-content: space-between;">
                                <img src="../assets/img/seta-esquerda.png" alt="">
                            </button>
                            <div style="display: flex; flex-direction: column;">
                                <label style="color: #101142; font-size: 14px; text-align: start;"><strong>LISTA DE
                                        CONVIDADOS</strong></label>
                                <label style="color: #101142; font-size: 14px; text-align: start;">Voltar</label>
                            </div>
                        </div>
                    </div>

                    <div>

                        <img src="../assets/img/check.png" style="width: 30%;">

                        <p
                            style="text-transform: uppercase; color: #101142; word-wrap: break-word;  overflow-wrap: break-word; white-space: normal; ">
                            FAZER CHECK-IN DE <br> "{{ this.nomeparticipante }}"</p>

                        <button @click="checkin(this.idparticipante)" class="tavai3">CONFIRMAR CHECK-IN</button>

                    </div>

                </div>


            </div>
        </div>

        <div class="ah1" v-if="active" style="display: flex; flex-direction: column; align-items: center; ">



            <h1 class="titulo" :style="confirmbutton ? { color: 'black' } : { color: '#101142' }">CHECK-IN</h1>
            <p @click="baixarplan()" style="margin-top: -8px; cursor: pointer;">Total: {{ this.qtdCheckin }}</p>
            <!-- <p class="paragrafo"
            
             :style="confirmbutton ? {color: 'black'} : {marginTop: '0px', marginBottom: '15%'}"
            >Lista de convidados</p> -->

            <input :style="confirmbutton ? { backgroundColor: '#202020f1', color: 'black' } : {}" class="input1"
                type="search" v-model="searchQuery" placeholder="Buscar convidado">



            <div v-if="searchQuery.length > 0" class="container2">
                <div v-if="filteredItems.length > 0">
                    <li :style="confirmbutton ? { backgroundColor: '#202020f1', color: 'black' } : {}"
                        :class="getBackgroundClass(index)" v-for="(convidado, index) in filteredItems" :key="index">

                        <div style="width: 70%;text-align: start;">

                            <div style="display: flex; align-items: center;">
                                <img v-if="convidado.tipo.toLowerCase() === 'vip'" src="../assets/img/VIP.png"
                                    style=" margin-right: 2% ;width: 7%; height: 60%;">
                                <img v-else-if="convidado.tipo.toLowerCase() === 'palestrante'"
                                    src="../assets/img/PALESTRANTE.png" style=" margin-right: 2% ;width: 6%;">
                                <img v-else-if="convidado.tipo.toLowerCase() === 'premiado'"
                                    src="../assets/img/PREMIADO.png" style=" margin-right: 2% ;width: 7%; height: 15px">
                                <img v-else-if="convidado.tipo.toLowerCase() === 'patrocinador'"
                                    src="../assets/img/patrocinador.png"
                                    style=" margin-right: 2% ;width: 8%; height: 15px">
                                <label class=" truncate-text" style="color:black; font-size: 16px;"><strong>{{
                                    convidado.nome.charAt(0).toUpperCase() +
                                        convidado.nome.slice(1)}}</strong></label>
                            </div>
                            <div>
                                <label style="text-align: start; color: #888888; font-size: 11px;"
                                    class=" truncate-text">{{ convidado.empresa }}</label>
                            </div>
                        </div>

                        <div style="width: 25%;text-align: center; justify-content: center;" class="mob222"
                            v-if="convidado.isInscrito && convidado.tipo !== 'OUTROS'">
                            <img src="../assets/img/smsenviado.png" style="width: 20px;">
                        </div>
                        <div style="width: 25%;text-align: center;" class="mob222" v-else-if="convidado.isInscrito">
                            <img src="../assets/img/OUTROS.png" style="width: 20px;">
                        </div>
                        <div class="ppp" style="width: 25%;text-align: center;" v-else>
                            <button :style="confirmbutton ? { backgroundColor: '#202020f1', color: 'black' } : {}"
                                class="btn-checkin" @click="checkinQuestiona(convidado.idParticipante)">CHECK-IN
                            </button>
                        </div>
                    </li>

                </div>

                <div v-else>
                    <img src="../assets/img/icone-erro.gif" style="margin-top: 5%;">
                    <p style="font-size: 16px;"><strong>NÃO FOI POSSÍVEL ENCONTRAR</strong></p>
                    <p style="font-size: 16px; margin: 0">"{{ searchQuery }}"</p>
                    <button style="font-size: 18px;" @click="cadastrarconvidado(this.searchQuery)"
                        class="btncadastrar">CADASTRAR</button>
                </div>

            </div>

            <!-- <div v-else>
                <button style="margin-top: 25px; font-size: 18px;" @click="cadastrarconvidado(this.searchQuery)" class="btncadastrar">CADASTRAR CONVIDADO</button>
            </div> -->




        </div>

        <CheckinConvidado v-if="!active" @passandoactive="recebendoative" @status="receberstatus"
            :dadoenviado="this.idParticipanteEdit" />

    </div>
</template>

<script>
import api from '@/api';
import CheckinConvidado from './CheckinConvidado.vue';

export default {
    name: 'ListaConvidado',
    data() {
        return {
            qtdCheckin: 0,
            participantes: [],
            searchQuery: '',
            active: true,
            nomeEvento: '',
            eventoID: null,
            email: '',
            exibirDiv: false,
            modaativo: false,
            confirmbutton: false,
            tipo: null,
            nomeparticipante: '',
            tipoparticipante: '',
            empresaparticipante: '',
            tentarcheckin: false,
            idparticipante: '',
            modaativo: false,
            idParticipanteEdit: '',
        }
    },
    components: {
        CheckinConvidado
    },
    computed: {
        filteredItems() {
            return this.participantes.filter(convidado => {
                const searchLower = this.searchQuery.toLowerCase();
                return (
                    convidado.nome.toLowerCase().startsWith(searchLower)
                    // || convidado.email.toLowerCase().startsWith(searchLower)
                    // || convidado.cpf && convidado.cpf.startsWith(searchLower)  
                );
            });
        }


    },
    methods: {
        baixarplan() {
            api.get(`/v1/participacao/exportar-excel?idEvento=${this.eventoID}`, {
                responseType: 'blob' // Especifica que a resposta será um blob (arquivo binário)
            }).then(response => {
                // Cria um URL para o blob
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Cria um link temporário
                const link = document.createElement('a');
                link.href = url;

                // Define o nome do arquivo para download
                link.setAttribute('download', 'Convidados.xlsx');

                // Adiciona o link ao documento e aciona o clique
                document.body.appendChild(link);
                link.click();

                // Remove o link após o uso
                document.body.removeChild(link);
            }).catch(error => {
                console.error('Erro ao baixar a planilha:', error);
            });
        }
        ,
        editcadastro() {
            this.confirmbutton = false;
            this.active = false;
            this.idParticipanteEdit = this.idparticipante;
        },
        receberstatus() {
            this.active = true
            this.getconvidados()
        },
        cadastrarconvidados() {
            this.active = false;
            this.modaativo = false;
            this.idParticipanteEdit = null
        },
        openmodal() {
            this.modaativo = true
        },
        deslogar() {
            this.$router.push('/');
            localStorage.removeItem('authToken');
            this.modaativo = false
        },
        fecharmodal() {
            this.modaativo = false
        },

        tentarfazerCheckin() {
            this.tentarcheckin = true
        },

        moverparacadastro() {
            this.$router.push('./checkin-convidado')
            localStorage.setItem('nomedigitado', '')
        },
        exibiropcoes() {
            this.exibirDiv = !this.exibirDiv
        },
        cadastrarconvidado() {
            this.$router.push('/checkin-convidado')
            localStorage.setItem('nomedigitado', this.searchQuery)
            // this.active = false
            // this.modaativo = false
            this.searchQuery = ''
            this.idParticipanteEdit = null
        },
        recebendoative(active) {
            this.active = active
        },
        checkinQuestiona(id) {
            this.confirmbutton = true;
            this.idparticipante = id

            const procureocara = this.participantes.find(participante => participante.idParticipante === id);

            if (procureocara) {
                this.nomeparticipante = procureocara.nome
                this.tipoparticipante = procureocara.tipo
                this.empresaparticipante = procureocara.empresa
            }

            this.editcadastro()

        },
        checkin(id) {



            const dados = {
                idEvento: this.eventoID,
                idParticipante: id,
                email: this.email,
            }
            api
                .post('/v1/Participacao', dados)
                .then(() => {
                    this.getconvidados()
                    this.tentarcheckin = false
                    this.confirmbutton = false
                })
                .catch((error) => {
                    console.log(error)
                })




        },
        getBackgroundClass(index) {
            return index % 2 === 0 ? 'background-white' : 'background-gray';
        },
        deslogar() {
            this.$router.push('/');
            localStorage.removeItem('authToken');
        },
        getconvidados() {
            api
                .get(`v1/Participante/evento/${this.eventoID}`)
                .then((resp) => {

                    this.qtdCheckin = resp.data.filter(item => item.isInscrito === true).length

                    this.participantes = resp.data;

                })
                .catch((error) => {
                    console.log('Erro ao buscar convidados:', error);
                });
        }
    },
    mounted() {

        this.nomeEvento = localStorage.getItem('nomeEvento') || null;
        this.eventoID = localStorage.getItem('eventoID') || null;
        this.email = localStorage.getItem('email') || null;
        this.getconvidados();
    },
}
</script>

<style scoped>
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    font-weight: normal;
}

.menu {
    position: relative;
    display: inline-block;
}

.menu:hover {
    cursor: pointer;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #44444494;
    padding: 5px 0;
    min-width: 100px;
    z-index: 100;
}

.menu-item:hover .submenu {
    display: block;
}

.background-gray {
    background-color: #FFFFFF;
}

a:hover {
    background-color: white;
    color: black !important;
    margin: 0 10px
}

.submenu a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    /* background-color: #44444450; */
}

.truncate-text {
    display: inline-block;
    /* Ou inline se for para texto dentro de uma linha */
    max-width: 100%;
    /* Defina o tamanho máximo que o texto pode ocupar */
    white-space: nowrap;
    /* Não permite quebra de linha */
    overflow: hidden;
    /* Esconde o texto que ultrapassa o tamanho do contêiner */
    text-overflow: ellipsis;
    /* Adiciona '...' ao final do texto */
}

.hover:hover {
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202020f1;
    z-index: 9999;
}

.overlay2 {
    position: fixed;
    top: 20%;
    left: 25%;
    width: 50%;
    height: 75%;
    background: #ffffff;
    z-index: 9999;
    overflow: hidden !important;
}

.headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202020f1;
    z-index: 9999;
}



@media(min-width: 991px) {
    .edittamaho {
        width: 5% !important;
    }

    .btn-checkin {
        height: 30px;
    }

    .overlay {
        padding-top: 5% !important;
    }
}

@media(max-width: 990px) {

    .imgcardapio {
        width: 30px !important;
        height: 30px !important;
    }

    .overlay2 {
        height: 50% !important;
        left: 12.5% !important;
        width: 75% !important;
    }

    .mgbt5 {
        padding-top: 5%;
        margin-bottom: 5% !important
    }

    .mobizar {
        margin-top: 5%;
    }

}

.hover:hover {
    cursor: pointer;
}

.btncadastrar {
    margin-top: 5%;
    border: none;
    background-color: #101142;
    color: white;
    padding: 7px 20px;
    border-radius: 15px;
    height: 50px;
    font-size: 20px;
}

.btncadastrar:hover {
    cursor: pointer
}

.background-white {
    background-color: #F8F8F8;
}

.labelli {
    width: 33% !important;
    max-width: 33% !important;
    word-wrap: break-word;
    margin-right: 2px;
}

.background-gray,
.background-white {
    margin-bottom: 1%;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
}

.tavai:hover,
.tavai2:hover,
.tavai3:hover {
    cursor: pointer;
}

.btn-checkin {
    border: none;
    background-color: #101142;
    color: white;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.btn-checkin:hover {
    cursor: pointer;
}

.container2 {
    margin-top: 3%;
    width: 40%;
}

.input1 {
    width: 22%;
    height: 30px;
    border: 2px solid #101142;
    font-size: 16px;
    color: rgb(52, 52, 52)
}

.input1::placeholder {
    font-size: 16px;
    color: rgba(52, 52, 52, 0.767);
}

.headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dvvi {
    background-color: aliceblue;
    padding: 5px;
}



.titulo {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-top: 30px !important;
    margin-bottom: 10px !important;

}

.paragrafo {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: black;
    font-size: 16px
}

.inputpadrao {
    width: 164px;
    margin-bottom: 1%;
    border: 1px solid #101142;
}

.btn-enviar {
    height: 35px;
    width: 250px;
    background-color: #101142;
    color: white;
    margin-top: 1%
}

.btn-sair:hover,
.btn-enviar:hover {
    cursor: pointer;
}

.tavai {
    font-size: 22px;
    border: none;
    background-color: #101142;
    height: 50px;
    width: 32%;
    margin-bottom: 2%;
    color: white;
    margin-top: 2%
}

.tavai2 {
    font-size: 22px;
    border: none;
    background-color: #429F3B;
    height: 50px;
    width: 32%;
    color: white;
}

.tavai3 {
    font-size: 22px;
    border: none;
    background-color: #429F3B;
    height: 50px;
    width: 32%;
    color: white;
}

@media(max-width: 990px) {

    .titulo {
        margin-top: 10%;
        margin-bottom: 5%;
    }

    .mgbt5 {
        margin-bottom: 5% !important
    }



    .tavai {
        font-size: 13PX;
        border: none;
        background-color: #101142;
        height: 45px;
        width: 80%;
        margin-bottom: 5%;
        color: white;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    }

    .tavai2 {
        font-size: 13PX;
        border: none;
        background-color: #429F3B;
        height: 45px;
        width: 80%;
        color: white;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    }

    .tavai3 {
        font-size: 13PX;
        border: none;
        background-color: #429F3B;
        height: 50px;
        width: 80%;
        color: white;
    }

    button:hover {
        cursor: pointer;
    }



    .headerr {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        padding-top: 5%;
    }

    .labelli {
        font-size: 13px;
    }

    .fontzi {
        font-size: 15px !important
    }

    .btn-checkin {
        font-size: 3vw;
        width: 74px;
        height: 42px;
        white-space: nowrap;
    }

    .ppp {
        padding-top: 0% !important;
        display: flex;
        align-items: center;
    }

    .background-gray,
    .background-white {
        margin-bottom: 1%;
        display: flex;
        justify-content: space-between;
        padding: 15px 5px
    }

    .ah1 {
        /* margin: -25px;
        margin-top: 5%; */
    }

    .mob222 {
        max-width: 100px;
    }

    .inputpadrao {
        width: 62%;
        margin-bottom: 4%;
        border: 1px solid #101142;
        height: 41px;
    }

    .btn-enviar {
        height: 50px;
        width: 261px;
        font-size: 22px;
        background-color: #101142;
        color: white;
        margin-top: 3%;
    }

    label {
        font-size: 23px;
    }

    p {
        font-size: 20px
    }

    .input1 {
        height: 52px;
        width: 75%;
    }

    .container2 {
        margin-top: 5%;
        width: 80%;
    }
}
</style>