// src/services/api.js
import axios from 'axios';

// Configurando o cliente Axios com a URL base da API
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default api;
