import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css'; // Importando o CSS do Toastify

// Crie a instância do app
const app = createApp(App);

// Configure o Toastify
app.use(Toast, {
  autoClose: 1000, // Tempo de exibição das notificações (3 segundos)
  position: 'top-right', 
});

app.use(router).mount('#app');
