import CheckinConvidado from '@/components/CheckinConvidado.vue'
import FormularioEdit from '@/components/FormularioEdit.vue'
import ListaConvidado from '@/components/ListaConvidado.vue'
import Login from '@/components/Login.vue'
import MostrarConvidados from '@/components/MostrarConvidados.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import Enriquecimento from '@/components/Enriquecimento.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/lista-convidado',
    name: 'ListaConvidado',
    component: ListaConvidado,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin-convidado',
    name: 'CheckinConvidado',
    component: CheckinConvidado,
    meta: { requiresAuth: true }
  },
  {
    path: '/mostrar-convidado',
    name: 'MostrarConvidado',
    component: MostrarConvidados,
    meta: { requiresAuth: true }
  },
  {
    path: '/FormularioEdit',
    name: 'FormularioEdit',
    component: FormularioEdit,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/enriquecimento',
  //   name: 'Enriquecimento',
  //   component: Enriquecimento,
  //   meta: { requiresAuth: true }
  // },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('authToken');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/'); 
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router
