<template>
    <div
        :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', margin: '-30px', height: '110vh', overflow: 'hidden' } : {}">
        <div v-if="exibirMensagemSucesso" class="exibirmensagemdesucesso">
            <img src="../assets/img/icone-sucesso.gif" style="width: 30%; margin-bottom: 5%">
            <label style="color: #101142; font-size: 14px; font-family: 'Montserrat';     margin-bottom: 20px;
"><strong>CADASTRO REALIZADO <br> COM SUCESSO</strong></label>
            <button @click="fecharconfirmacaocheckin()" class="btn-enviar quatro"
                style="margin-top: 5%; font-size: 18px; width: 220px;">FECHAR</button>
        </div>

        <div v-if="exibirMensagemSucesso2" class="exibirmensagemdesucesso">
            <img src="../assets/img/icone-sucesso.gif" style="width: 30%; margin-bottom: 5%">
            <label style="color: #101142; font-size: 14px; font-family: 'Montserrat';     margin-bottom: 20px;
"><strong>CHECK-IN REALIZADO <br> COM SUCESSO</strong></label>
            <button @click="fecharconfirmacaocheckin()" class="btn-enviar quatro"
                style="margin-top: 5%; font-size: 18px; width: 200px;">FECHAR</button>
        </div>

        <div v-if="modaativo" class="overlay" style="display: flex; justify-content: space-between; padding-top: 9%;">

            <div style="padding-left: 2.5%;">
                <button class="hover" @click="fecharmodal()"
                    style="border: none; background-color: transparent; color: white; display: flex; align-items: center">
                    <img src="../assets/img/icone-sair.png" alt="">
                    <!-- <label class="hover" for="" style="margin-left: 10%; font-size: 16px; text-decoration: underline;">VOLTAR</label> -->
                </button>
            </div>

            <div style="display: flex; flex-direction: column; align-items: end; padding-right: 5%;">

                <a @click="cadastrarconvidados()" class="mgbt5 hover"
                    style="color: white; text-decoration: underline; vertical-align: top; padding-top: 4px; font-size: 16px">CHECK-IN
                    CONVIDADO
                </a>
                <a @click="deslogar()" class="hover"
                    style="color: white; text-decoration: underline; display: flex; align-items: center; font-size: 16px">SAIR<img
                        src="../assets/img/sair.png" style="margin-left: 10%;">
                </a>

            </div>
        </div>

        <div class="headerr">
            <img 
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { opacity: '0' } : { width: '12%' }"
            src="../assets/img/LOGO.png" class="edicao">

            <div class="menu">
                    <div class="menu-item" style="    display: flex; justify-content: end;">
                        <img src="../assets/img/cardapio.png" style="width: 40%;" class="">
                        <div class="submenu">
                            <a v-if="qualbotao" @click="this.$router.push('/lista-convidado')">Check-in</a>
                            <a v-else @click="this.$emit('status', false)">Check-in</a>
                            <a @click="deslogar()" >Deslogar</a>
                        </div>
                </div>
            </div>

        </div>

        <h1 class="titulo"
            :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { color: 'black', marginTop: '30px', marginBottom: '30px' } : { color: '#101142', marginTop: '30px', marginBottom: '30px' }">
            CADASTRO DE CONVIDADOS</h1>


        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <label :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { color: 'black !important' } : {}"
                class="labelparainput">{{ nomeLabel }}</label>
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="nome" class="inputpadrao" type="text" placeholder="Nome Completo" @input="blockNumbers"
                @keydown.enter="focusNext('campo2')">

            <label :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { color: 'black !important' } : {}"
                class="labelparainput">{{ cpfLabel }}</label>
                <input 
    v-model="cpfFormatado" 
    @input="formatarCpf" 
    class="inputpadrao" 
    type="tel"
    placeholder="CPF"
    maxlength="14" 
    ref="campo2"
    @keydown.enter="focusNext('campo3')"
/>

            <label class="labelparainput">{{ empresaLabel }}</label>
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="empresa" class="inputpadrao" type="text" placeholder="Empresa" ref="campo3"
                @keydown.enter="focusNext('campo4')">

                <select v-model="nivelHierarquico" class="inputpadrao2" placeholder="Nivel Hierárquico"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}">
                <option value="">Nível Hierárquico</option>
                <option value="CEO">CEO</option>
                <option value="PRESIDENTE">Presidente</option>
                <option value="VICE-PRESIDENTE">Vice-presidente</option>
                <option value="DIRETOR">Diretor</option>
                <option value="GERENTE">Gerente</option>
                <option value="COORDENADOR">Coordenador</option>
                <option value="SUPERVISOR">Supervisor</option>
                <option value="ANALISTA">Analista</option>
                <option value="OUTROS">Outros</option>
            </select>



            <label class="labelparainput">{{ cargoLabel }}</label>
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                @input="blockNumberstoo" v-model="cargo" class="inputpadrao" type="text" placeholder="Cargo"
                ref="campo4" @keydown.enter="focusNext('campo5')">
            <label class="labelparainput">{{ emailLabel }}</label>
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                v-model="email" class="inputpadrao" type="text" placeholder="E-mail" @input="formatInput" ref="campo5"
                @keydown.enter="focusNext('campo6')">
            <label v-if="telefoneFormatado" class="labelparainput"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { color: 'black' } : {}">Telefone:</label>
            <input
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}"
                placeholder="Telefone" v-model="telefoneFormatado" class="inputpadrao" type="tel"
                @input="formatarTelefone" maxlength="15" minlength="15" ref="campo5">
            <select v-model="categoria" class="inputpadrao2" placeholder="Categoria"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { backgroundColor: '#202020f1', border: 'none', color: 'black !important' } : {}">
                <option value="">Categoria</option>
                <option value="VIP">Vip</option>
                <option value="PALESTRANTE">Palestrante</option>
                <option value="PREMIADO">Premiado</option>
                <option value="PATROCINADOR">Patrocinador</option>
                <option value="OUTROS">Outros</option>
            </select>

            <div v-if="qualbotao" class="checkdiv">
                <input v-model="checkinFlag" class="inputcheck" type="checkbox"
                    :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { display: 'none' } : {}">
                <label :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { color: 'black' } : {}">Fazer o Check
                    In</label>
            </div>


            <button v-if="qualbotao" class="btn-enviar" @click="cadastrar()"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { display: 'none' } : { fontSize: '18px' }">CADASTRAR</button>
            <button v-else class="btn-enviar2" @click="editarCadastro()"
                :style="exibirMensagemSucesso || exibirMensagemSucesso2 ? { display: 'none' } : { fontSize: '18px' }">CHECK-IN</button>

            <p v-if="erro">{{ erro }}</p>
        </div>
        <br>
    </div>
</template>


<script>
import api from '@/api';


export default {
    name: 'CheckinConvidado',
    data() {
        return {
            nome: '',
            empresa: '',
            cargo: '',
            email: '',
            cpf: '',
            cpfFormatado: '',
            emailError: '',
            telefone: '',
            telefoneFormatado: '',
            categoria: '',
            nivelHierarquico: '',
            checkinFlag: false,
            eventoID: '',
            emailUsuarioLogado: '',
            participantesGeral: '',
            participanteID: '',
            nomeEvento: '',
            erro: '',
            exibirMensagemSucesso: false,
            exibirMensagemSucesso2: false,
            modaativo: false,
            qualbotao: true,
            dadorecebido: '',
        };
    },
    computed: {
        nomeLabel() {
            return this.nome === '' ? '' : 'Nome:';
        },
        empresaLabel() {
            return this.empresa === '' ? '' : 'Empresa:';
        },
        cargoLabel() {
            return this.cargo === '' ? '' : 'Cargo:';
        },
        emailLabel() {
            return this.email === '' ? '' : 'E-mail:';
        },
        telefoneLabel() {
            return this.telefone === '' ? '' : 'Telefone:';
        },
        cpfLabel() {
            return this.cpf === '' ? '' : 'CPF:';
        }
    },
    props: {
        dadoenviado: {
            type: String,
            required: false,
        },
    },
    methods: {
        formatarCpf(event) {
        let cpf = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres que não sejam números

        if (cpf.length > 11) {
            cpf = cpf.substring(0, 11); // Limita o CPF a 11 dígitos
        }

        // Aplica a máscara de CPF (000.000.000-00)
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

        this.cpfFormatado = cpf;

        // Armazena o CPF sem formatação
        this.cpf = cpf.replace(/\D/g, ''); // Armazena o CPF apenas com números
    },


        focusNext(refName) {
            this.$refs[refName].focus();
        },
        formatInput(event) {
            this.email = event.target.value.toLowerCase();
        },
        blockNumbers(event) {
            this.nome = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        },


        blockNumberstoo(event) {
            this.cargo = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        },
        editarCadastro() {



            const data = {
                idParticipante: this.dadorecebido,
                idEvento: this.eventoID,
                emailUser: this.emailUsuarioLogado,
                nome: this.nome,
                email: this.email,
                celular: this.telefone,
                tipo: this.categoria,
                nivelHierarquico: this.nivelHierarquico,
                cargo: this.cargo,
                empresa: this.empresa,
                cpf: this.cpf
            };

            api
                .put('v1/Participante', data)
                .then(() => {

                    this.fazercheckin();

                    this.nome = '';
                    this.email = '';
                    this.telefone = '';
                    this.categoria = '';
                    this.nivelHierarquico = '',
                    this.cargo = '';
                    this.empresa = '';
                    this.telefoneFormatado = '';
                    this.checkinFlag = false;
                    this.cpf = '';
                    this.dadorecebido = ''
                    this.exibirMensagemSucesso2 = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        edicaobutton() {
            if (this.dadorecebido) {
                api
                    .get('v1/Participante')
                    .then((resp) => {

                        let participanteSelecionado = resp.data.find(participante => participante.idParticipante === this.dadorecebido);

                        this.nome = participanteSelecionado.nome
                        this.empresa = participanteSelecionado.empresa
                        this.cargo = participanteSelecionado.cargo
                        this.email = participanteSelecionado.email
                        this.telefoneFormatado = participanteSelecionado.celular
                        this.categoria = participanteSelecionado.tipo,
                        this.nivelHierarquico = participanteSelecionado.nivelHierarquico,
                        this.checkinFlag = participanteSelecionado.isEnscrito
                        this.cpf = participanteSelecionado.cpf
                        this.qualbotao = false

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        openmodal() {
            this.modaativo = true
        },
        deslogar() {
            this.$router.push('/');
            localStorage.removeItem('authToken');
            this.modaativo = false
        },
        fecharmodal() {
            this.modaativo = false
        },
        cadastrarconvidados() {
            this.limparDados()
            this.dadorecebido = ''
            this.$emit('status', false)
            this.modaativo = false
            this.edicaobutton()
        },
        voltar() {
            this.$router.push('./lista-convidado');
        },

        // validateEmail() {
        //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //     if (!emailPattern.test(this.email)) {
        //         this.emailError = 'Por favor, insira um e-mail válido.';
        //     } else {
        //         this.emailError = ''; 
        //     }
        // },
        formatarTelefone() {
            let apenasNumeros = this.telefoneFormatado.replace(/\D/g, '');
            if (apenasNumeros.length > 11) {
                apenasNumeros = apenasNumeros.slice(0, 11);
            }
            if (apenasNumeros.length <= 10) {
                this.telefoneFormatado = apenasNumeros.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
            } else {
                this.telefoneFormatado = apenasNumeros.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
            }
            this.telefone = apenasNumeros;
        },
        deslogar() {
            this.$router.push('/');
            localStorage.removeItem('authToken');
        },
        fecharconfirmacaocheckin() {
            this.exibirMensagemSucesso = false
            this.$emit('status', false);
            this.$router.push('/lista-convidado')
        },
        async cadastrar() {
    if (!this.nome || !this.email) { 
        this.erro = 'Por favor, preencha o nome e o e-mail.';
        setTimeout(() => {
            this.erro = null;
        }, 1000);
        return; 
    }

    this.erro = '';

    const data = {
        idEvento: this.eventoID,
        emailUser: this.emailUsuarioLogado,
        nome: this.nome,
        email: this.email,
        celular: this.telefone,
        tipo: this.categoria,
        nivelHierarquico: this.nivelHierarquico,
        cargo: this.cargo,
        empresa: this.empresa,
        cpf: this.cpf,
    };

    try {
        await api.post('v1/Participante', data);

        if (this.checkinFlag) {
            await this.getParticipantes();
        }

        await this.limparDados();

        this.exibirMensagemSucesso = true;

    } catch (error) {
        console.error('Erro ao cadastrar participante:', error);
    }
}
,
        async limparDados() {
            this.nome = '';
            this.email = '';
            this.telefone = '';
            this.categoria = '';
            this.cargo = '';
            this.empresa = '';
            this.telefoneFormatado = '';
            this.checkinFlag = false;
            this.dadorecebido = ''
            this.cpf = ''
        },
        async getParticipantes() {
            try {
                const resp = await api.get('v1/Participante');
                const participante = resp.data.find(participante => participante.nome === this.nome);

                if (participante) {
                    this.participanteID = participante.idParticipante;
                    if (this.participanteID) {
                        await this.fazercheckin();
                    }
                } else {
                    console.error('Participante não encontrado');
                }
            } catch (error) {
                console.error('Erro ao buscar participantes:', error);
            }
        },
        async fazercheckin() {
            const dados = {
                idEvento: this.eventoID,
                idParticipante: this.participanteID || this.dadorecebido,
                email: this.emailUsuarioLogado,
            };

            try {
                await api.post('v1/Participacao', dados);
            } catch (error) {
                console.error('Erro ao realizar o check-in:', error);
            }
        }
    },
    mounted() {
        this.eventoID = localStorage.getItem('eventoID');
        this.emailUsuarioLogado = localStorage.getItem('email');
        this.nomeEvento = localStorage.getItem('nomeEvento');
        this.nome = localStorage.getItem('nomedigitado' || null);
        this.dadorecebido = this.dadoenviado
        this.edicaobutton()
    }
};




</script>

<style>
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    font-weight: normal;
}

.menu {
    position: relative;
    display: inline-block;
}

.menu:hover{
    cursor: pointer;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #44444494;
    padding: 5px 0;
    min-width: 100px;
    z-index: 100;
}

.menu-item:hover .submenu {
    display: block;
}

.background-gray {
    background-color: #FFFFFF;
}

a:hover{
    background-color: white;
    color: black !important;
    margin: 0 10px
}

.submenu a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    /* background-color: #44444450; */
}


.error-message {
    color: red;
    font-size: 14px;
}

.mensagemsucesso {
    background-color: rgb(129, 238, 129);
    color: black;
    padding: 5px;
    border-radius: 3px;
}

.labelparainput {
    text-align: start;
    width: 30%;
}

.inputpadrao::placeholder {
    font-size: 14px;
}

.headerr {
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
    padding-top: 5%;
}

.hover:hover {
    cursor: pointer;
}

.headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202020f1;
    z-index: 9999;
}

.overlay2 {
    position: fixed;
    top: 20%;
    left: 7.5%;
    width: 80%;
    height: 75%;
    background: #ffffff;
    z-index: 9999;
}

.exibirmensagemdesucesso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    height: 50%;
    width: 80%;
    z-index: 9999;
    top: 25%;
    left: 10%;
}


@media(max-width: 990px) {

    .overlay2 {
        height: 65%;
    }

    .mgbt5 {
        padding-top: 5%;
        margin-bottom: 5% !important
    }

    .titulo {
        font-size: 16px;
        margin-top: 7%;
        margin-bottom: 5%;
    }

}

.titulo {
    font-size: 18px;

}

.inputcheck {
    height: 100%;
    width: 25px;
    margin-right: 4%
}

.deslogar-btn:hover {
    cursor: pointer;
}

.inputpadrao {
    width: 400px;
    height: 55px;
    margin-bottom: 1%;
    border: 2px solid #101142;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #101142;
    padding-left: 10px;
    font-size: 16px !important;
}

@media(min-width: 991px) {
    .inputpadrao {
        width: 200px;
        height: 25px;
        margin-bottom: 1%;
        border: 2px solid #101142;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #101142;
        padding-left: 10px;
        font-size: 16px !important;
    }

    .inputpadrao2 {
        width: 215px !important;
        height: 32px !important;
        border: 2px solid #101142;
        margin-bottom: 1%;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #101142;
        background-color: white;
        padding-left: 10px;
        margin-left: 0.1px;
    }

    .btn-enviar {
        width: 215px !important;
        height: 35px !important;
    }

    .labelparainput {
        width: 200px !important;
    }

}

.checkdiv {
    display: flex;
    width: 22%;
    height: 33px;
    justify-content: center;
    align-items: center;
}

input::placeholder {
    color: #101142;
}

.inputpadrao2 {
    width: 416px;
    height: 61px;
    border: 2px solid #101142;
    margin-bottom: 1%;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #101142;
    background-color: #ECECEC;
    padding-left: 10px;
    margin-left: 0.1px;
}


.btn-enviar {
    height: 55px;
    width: 416px;
    background-color: #101142;
    font-size: 25px;
    color: white;
    margin-top: 1%;
    letter-spacing: 2px;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 15px;
    border: none;
}

.btn-enviar2 {
    height: 55px;
    width: 416px;
    background-color: #429F3B;
    border: none;
    font-size: 25px;
    color: white;
    margin-top: 1%;
    letter-spacing: 2px;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 15px;
    border: none;
}

button:hover {
    cursor: pointer;
}

.btn-enviar:hover {
    cursor: pointer;
}

.btnhover:hover {
    cursor: pointer
}

@media(max-width: 990px) {
    .inputpadrao {
        width: 89%;
        margin-bottom: 6%;
        border: 1px solid #101142;
        height: 41px;
        padding-left: 1%;
    }

    .btn-enviar {
        height: 50px;
        width: 90%;
        font-size: 22px;
        background-color: #101142;
        color: white;
        margin-top: 5%;
    }

    .btn-enviar2 {
        height: 50px;
        width: 275px;
        font-size: 18px;
        background-color: #429F3B;
        color: white;
        margin-top: 5%;
    }

    .labelparainput2 {
        width: 71% !important
    }

    .labelparainput23 {
        width: 93% !important;
        text-align: start;
        font-size: 14px !important;

    }

    .labelparainput {
        width: 90% !important;
        font-size: 14px !important;

    }

    label {
        font-size: 16px;
    }

    p {
        font-size: 20px
    }

    .input1 {
        height: 52px;
        width: 50%;
    }

    .checkdiv {
        width: 100%;

    }

    .inputpadrao2 {
        width: 91%;
        height: 45px;
        border: 2px solid #101142;
        margin-bottom: 5%;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #101142;
        background-color: #ECECEC;
        padding-left: 10px;
    }

    .imgcardapio {
        width: 30px !important;
        height: 30px !important;
    }

}



@media(min-width: 991px) {
    .overlay {
        padding-top: 5% !important;
    }

    .edicao {
        width: 5% !important;
    }

    .exibirmensagemdesucesso {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: white;
        height: 56%;
        width: 38%;
        z-index: 9999;
        top: 20%;
        left: 34%;
    }

    .quatro {
        width: 40% !important;
    }

    .btn-enviar2 {
        font-size: 14px !important;
    }
}
</style>